<template>
    <div class="p-2" v-if="pet">

        <!-- header -->
        <div class="d-flex align-items-center" style="gap:10px 20px">
            <div>
                <img :src="pet.pet_picture ? pet.pet_picture : default_img" class="rounded-circle"
                    style="height:80px;width:80px" />
            </div>
            <div>
                <h3 class="text-white h4 mb-0">{{ pet.pet_name }}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{ pet.pet_breed }}</h3>
                <h3 class="text-white h4 mb-0">{{ pet.pet_age }} Months, {{ pet.pet_gender }}</h3>
            </div>
        </div>
        <!-- end header -->

        <div class="d-flex mt-3 mb-1 align-items-center justify-content-between" style="gap:10px">
            <div class="h3 font-weight-bolder text-white mb-0">
                Choose Availability
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-between mt-1" style="gap:10px">
            <div class="text-white font-weight-normal h4 mb-0">Closed For Walker</div>
            <input class="form-radio-input" name="pet-choose" v-model="open_available" value="n" type="radio" />
        </div>
        <div class="d-flex align-items-center justify-content-between mt-1" style="gap:10px">
            <div class="text-white font-weight-normal h4 mb-0">Open For Walker</div>
            <input class="form-radio-input" name="pet-choose" v-model="open_available" value="y" type="radio" />
        </div>

        <div class="mt-3">

            <div class="d-flex align-items-center mb-1" style="gap:10px">
                <input class="flex-fill form-control" type="date"
                    style="background: transparent;color: white;border:2px solid #E3A230;" v-model="date_input" />
                <input class="flex-fill form-control" type="time"
                    style="background: transparent;color: white;border:2px solid #E3A230;" v-model="time_input" />
            </div>

            <textarea rows="3" class="form-control" placeholder="Note For Walker" v-model="text_input"
                style="background: transparent;color: white;border:2px solid #E3A230;">
            </textarea>

            <div class="mt-1 d-flex">
                <div class="text-white btn mx-auto"
                    style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0" @click="add_note()">Submit</div>
                </div>
            </div>

        </div>


        <h3 class="text-center text-white font-weight-bold h4 mt-3">Your Pet Walking Notes</h3>
        <div class="w-100 mt-1" style="height:2px;background-color: #E3A230;"></div>

        <div class="mt-3" v-for="item in note">
            <div class="d-flex align-items-center text-white" style="gap:10px">
                <div>
                    {{ moment(item.wn_date).format('dddd, DD MMMM YYYY') }}, {{ item.wn_time }}
                </div>
                <i class="bx bx-trash ml-auto" @click="del_note(item.wn_id)" style="font-size: 18px;"></i>
            </div>

            <h5 class="h4 mt-1 font-weight-bolder text-white">Note for Walker</h5>
            <p class="text-white">
                {{ item.wn_text }}
            </p>
        </div>

        <!-- <div class="mt-3 d-flex">
            <div class="text-white btn mx-auto"
                style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                <div class="h4 text-white mb-0">Track Your Dog</div>
            </div>
        </div> -->

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'

import Swal from 'sweetalert2'

export default {
    computed: {
        pet() {
            return store.state.pet.PET_INFO
        },
        note() {
            return this.pet?.walker?.note
        },
        user(){
            return store.state.auth.USER
        },
    },
    watch: {
        pet(val) {
            if(val.user_id != this.user.user_id || !this.user){
                this.$router.push('/homepage')
            }

            if (val.walker) {
                this.open_available = val.walker.availability
            } else {
                let payload = new FormData()
                payload.append('inp[pet_id]', this.id)
                payload.append('inp[availability]', 'n')
                store.dispatch('walker/SetWalkerAvailability', payload).then(() => {
                    store.dispatch('pet/GetPet', this.id)
                })
            }
        },
        open_available(val) {
            let payload = new FormData()
            payload.append('inp[pet_id]', this.id)
            payload.append('inp[availability]', val)
            store.dispatch('walker/SetWalkerAvailability', payload).then(() => {
                store.dispatch('pet/GetPet', this.id)
            })
        }
    },
    methods: {
        del_note(id) {
            Swal.fire({
                title: 'Do you want to delete this note?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('walker/DeleteNote', id).then(() => {
                        store.dispatch('pet/GetPet', this.id)
                    })
                }
            })
        },
        add_note(){
            let payload = new FormData()
            payload.append('inp[wn_text]', this.text_input)
            payload.append('inp[wn_date]', this.date_input)
            payload.append('inp[wn_time]', this.time_input)
            payload.append('inp[wpa_id]', this.pet.walker.wpa_id)

            store.dispatch('walker/AddNote', payload).then(() => {
                store.dispatch('pet/GetPet', this.id)
                this.text_input = ''
                this.time_input = ''
                this.date_input = ''
            })
        }
    },
    mounted() {
        store.dispatch('pet/GetPet', this.id) 
    },
    data() {
        return {
            date_input: '',
            time_input: '',
            text_input: '',
            moment,
            open_available: 'n',
            id: this.$route.params.id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>

<style>
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}</style>